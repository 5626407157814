import flowActionTypes from './flow.types';

const initialState = {
  loading: false,
  error: null,
  flowSteps: null,
  flowName: null,
  flowAudience: null,
  flowResortLocation: null,
  flowLastUpdated: null
};

const sanitizeFlowScreens = flow => {
  const duplicatedFlow = { ...flow };
  if (duplicatedFlow.screens) {
    const formattedScreens =
      duplicatedFlow.screens?.map(screen => {
        const newScreen = { ...screen };
        Object.keys(screen).forEach(k => {
          if (typeof newScreen[k] === 'string') {
            newScreen[k] = newScreen[k].trim();
          }
        });
        return newScreen;
      }) ?? [];

    duplicatedFlow.screens = formattedScreens;

    return duplicatedFlow;
  } else {
    return flow;
  }
};

const flowStepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case flowActionTypes.FETCH_FLOW_STEPS_REQUEST:
      return { ...state, loading: true, error: null };
    case flowActionTypes.FETCH_FLOW_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        flowSteps: sanitizeFlowScreens(action.payload.data.flow),
        flowName: action.payload.flowName,
        flowAudience: action.payload.flowAudience,
        flowResortLocation: action.payload.resortLocation,
        flowLastUpdated: action.payload.flowLastUpdated
      };
    case flowActionTypes.FETCH_FLOW_STEPS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default flowStepsReducer;
