import { useLayoutEffect } from 'react';

const useLockBodyScroll = isOpen => {
  useLayoutEffect(() => {
    if (isOpen) {
      document.querySelector('#root')?.removeAttribute('aria-hidden');
      document.body.style.overflowY = 'hidden';
    }

    return () => (document.body.style.overflowY = 'visible');
  }, [isOpen]);
};

export default useLockBodyScroll;
