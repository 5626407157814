import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Reservation endpoints
 *
 * @class ReservationsApiClient
 * @extends {GWRApiClient}
 */
export default class ReservationsApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_RESERVATION_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  /**
   * @function
   * Get all reservations for the current logged in user
   */
  async getAllReservationsForCurrentUser() {
    const urlRequest = this.url;

    let request = await super.getApiCallAuth(urlRequest);

    return request;
  }

  /**
   * @function
   * Get a reservation by its Id
   * @param {int} reservationId
   */
  async getReservationsById(reservationId) {
    const urlRequest = `${this.url}/${reservationId}`;

    let request = await super.getApiCallAuth(urlRequest);

    return request;
  }

  /**
   * @function
   * Get's an object that specifies wether or not the reservetation is elligible for virtual check-in,
   * it's worth noticing that reservation is elligible for virtual checking if it's due to 24h, there
   * maybe some cases when the Nor1 API isn't synced so it may respond with a non elligible flag.
   * @param {number} reservationId Reservation number.
   */
  async getReservationCheckIn(reservationId) {
    const urlRequest = `${this.url}/${reservationId}/check-in`;

    const request = await super.getApiCallAuth(urlRequest);

    return request;
  }

  /**
   * @deprecated This function and the components where it's used should be removed in a future release.
   * Get all reservations by Phone number and last four numbers of the Credit Card
   * @param {string} phone
   * @param {int} creditCard
   */
  async getAllReservationsByPhoneAndCc(phone, creditCard) {
    const urlRequest = `${this.url}/phone`;

    let queryParams = {
      PhoneNumber: phone,
      LastFourCc: creditCard
    };

    let request = await super.getApiCallAuth(urlRequest, queryParams);

    return request;
  }

  /**
   * @function
   * Get all reservations by Email and last four numbers of the Credit Card
   * @param {string} email
   * @param {int} creditCard
   */
  async getAllReservationsByEmailAndCc(email, creditCard) {
    const urlRequest = `${this.url}/email`;

    let queryParams = {
      Email: email,
      LastFourCc: creditCard
    };

    let request = await super.getApiCallAuth(urlRequest, queryParams);

    return request;
  }

  /**
   * @function
   * Get all reservations by Reservation Id and Last Name
   * @param {int} reservationId
   * @param {string} lastName
   */
  async getReservationsByIdAndLastName(reservationId, lastName) {
    const urlRequest = `${this.url}/last-name`;

    let queryParams = {
      ReservationId: reservationId,
      LastName: lastName
    };

    let request = await super.getApiCall(urlRequest, queryParams);

    return request;
  }

  /**
   * @function
   * Get reservations by Reservation Id and Reservation Key
   * @param {number} reservationId
   * @param {string} reservationKey
   */
  async getReservationsByIdAndReservationKey(reservationId, reservationKey) {
    const urlRequest = `${this.url}/fetch`;

    const queryParams = {
      ReservationId: reservationId,
      ReservationKey: reservationKey
    };

    const request = await super.getApiCall(urlRequest, queryParams);

    return request;
  }

  /**
   * @function
   * Send a PUT request to cancel the reservation by Id.
   * @param {number} reservationId
   */
  async cancelReservationById(reservationId) {
    const actionUrl = `${reservationId}/cancel`;

    const urlRequest = `${this.url}/${actionUrl}`;

    let request = await super.putApiCallAuth(urlRequest, {});

    return request;
  }

  /**
   * @function
   * Send a PUT request to modify a reservation.
   * @param {Object} reservation Reservation payload.
   * The reservation modified fields
   */
  async modifyReservation(reservation) {
    const urlRequest = `${this.url}/modify`;

    let request = await super.putApiCall(urlRequest, reservation);

    return request;
  }

  /**
   * @function
   * Create a new reservation for the current user
   * @param {object} reservation
   */
  async createSuiteReservation(reservation) {
    const urlRequest = this.url;

    const shouldUseAuthCall = super.shouldUseAuthenticatedCalls();

    let request = null;

    // This to allow backend to update the User Profile
    if (shouldUseAuthCall) {
      request = await super.postApiCallAuth(urlRequest, reservation);
    } else {
      request = await super.postApiCall(urlRequest, reservation);
    }

    return request;
  }

  /**
   * Create a new daypass for the current user.
   * @param {Object} reservation payload object
   * @returns
   * @see {@link https://gwrdev-azure.greatwolf.com/reservation/index.html?urls.primaryName=V2} for information on model object.
   */
  async createDayPassReservation(reservation) {
    const urlRequest = `${this.url}/daypass`;

    return super.postApiCall(urlRequest, reservation);
  }

  /**
   * Add a cabana for your reservation
   * @param {Object} Cabanas payload object
   */
  async addCabana(cabanasRequest) {
    const urlRequest = `${this.url}/cabana`;
    return super.postApiCall(urlRequest, cabanasRequest);
  }

  async addCabanaV3(reservationId, cabanasRequest, lastName) {
    const urlRequest = `${this.url}/${reservationId}/packages/cabana?last-name=${lastName}`;
    return super.postApiCall(urlRequest, cabanasRequest);
  }

  async getCabanaDeposit(request) {
    const urlRequest = `${this.url}/${request.id}/packages/availability/cabana/${request.cabanaRoomCode}/${request.cabanaRateCode}?${request.extraParams}`;

    let result = await super.getApiCall(urlRequest, request.queryParams);

    return result;
  }

  async fetchCabanasV3(request) {
    const urlRequest = `${this.url}/${request.resId}/packages/availability/cabana`;

    let result = await super.getApiCall(urlRequest, request.queryParams);

    return result;
  }

  async getDynamicTilesV3(request) {
    const urlRequest = `${this.url}/${request.resId}/dynamic-tile`;

    let result = await super.getApiCallAuth(urlRequest, request.queryParams);

    return result;
  }

  async fakeGetDynamicTilesV3(request) {
    throw new Error('dynamic-tile disabled temporarily');
  }

  async getFolio(request) {
    const urlRequest = `${this.url}/${request.resId}/folio`;

    let result = await super.getApiCall(urlRequest, request.queryParams);

    return result;
  }

  async getDiningCredits(request) {
    const urlRequest = `${this.url}/${request.resId}/dining-credits`;

    let result = await super.getApiCall(urlRequest, request.queryParams);

    return result;
  }
}
