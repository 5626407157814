import uuidv4 from 'uuid/v4';
import { API_URLS, getApiUrl } from '../api/Utils/apiURLConfiguration';
import { LocalStorageKeys } from '../utilities/storageUtils';
import DocumentStoreRepository from './DocumentStoreRepository';

const listLength = 10;

export default class RecentSearchesRepository extends DocumentStoreRepository {
  constructor() {
    let urlAux = getApiUrl(API_URLS.REACT_APP_RECENT_SEARCHES_SERVICE_ENDPOINT, 'v1');
    let completeUrl = `${urlAux}/profiles/webusers/recent-searches`;

    super(completeUrl, LocalStorageKeys.RecentSearches);
  }

  async setRecentSearch(recentSearchData) {
    if (this.apiClient.isLoggedIn) {
      return super.setAuth(recentSearchData);
    } else {
      super.set(recentSearchData);
    }
  }

  async getAllRecentSearches() {
    let recentSearchesData = [];
    if (this.apiClient.isLoggedIn) {
      recentSearchesData = super.getAllAuth();
    } else {
      recentSearchesData = super.getAll();
    }

    return recentSearchesData;
  }

  async addNewRecentSearch(recentSearchesList, recentSearchData) {
    let recentSearchGuuid = uuidv4();

    try {
      let recentSearchToAdd = {
        ...recentSearchData,
        key: recentSearchGuuid,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      // If something fails then we don't mess up with the current recentSearchesList.
      // Get the first 9 elements
      let recentSearchesListClone = recentSearchesList.slice(0, listLength - 1);

      // add element at the top of the array
      recentSearchesListClone.unshift(recentSearchToAdd);

      await this.setRecentSearch(recentSearchesListClone);

      return recentSearchesListClone;
    } catch (error) {
      throw new Error('Error adding the recent search');
    }
  }

  async updateRecentSearch(recentSearchesList, recentSearchData) {
    try {
      let foundIndex = recentSearchesList.findIndex(x => x.key === recentSearchData.key);
      recentSearchesList[foundIndex] = recentSearchData;

      await this.setRecentSearch(recentSearchesList);

      return recentSearchesList;
    } catch (error) {
      throw new Error('Error on update the recent search');
    }
  }

  async deleteRecentSearchById(recentSearchesList, recentSearchId) {
    let filteredRecentSearches = recentSearchesList.filter(x => x.key !== recentSearchId);

    await this.setRecentSearch(filteredRecentSearches);

    return filteredRecentSearches;
  }

  async deleteRecentSearchesAll() {
    if (this.apiClient.isLoggedIn) {
      return super.deleteAllAuth();
    } else {
      super.deleteAll();
      return [];
    }
  }
}
