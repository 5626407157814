// Reservations Reducer

import { initializeState } from '../../../utilities/storageUtils';
import reservationsActionTypes from './reservations.types';

const INITIAL_STATE = {
  tempUserInfo: {},
  confirmedReservation: {},
  createdReservation: {},
  reservationList: [],
  upcomingReservationList: [],
  pastReservationList: [],
  canceledReservationList: [],
  isLoading: false,
  isInitialLoading: false,
  requestFailed: false,
  requestHasFinished: false,
  reservationUser: undefined,
  errorMessage: null,
  errorType: '',
  status: '',
  cancellationHasFailed: false,
  lastNameFindReservationById: '',
  reservationKeyFindReservationById: { key: '', reservationId: '' }
};

/**
 *
 * IMPORTANT!!!
 * The validation of reservation.isCabana is required to avoid showing cabanas reservations
 * this was implemented by story 30448, this is just a FE fix, the fix needs to be completed
 * on the BE and will be implemented on another story
 */
export function reservationListHandler(reservationData) {
  let reservationList = reservationData;
  let _now = new Date();

  // Split the reservation list into upcoming, past and canceled reservation list
  // Should be done right here. Placing this declarations in some other side may
  // lead this operations being executed multiple times
  let upcomingReservationList = reservationList.filter(reservation => {
    const arrivalDate = new Date(reservation?.arrival);
    let arrivalEndOfDay = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      23,
      59,
      59
    );
    return arrivalEndOfDay >= _now && !reservation?.isCanceled && reservation?.isCabana === false;
  });
  let pastReservationList = reservationList.filter(reservation => {
    const arrivalDate = new Date(reservation?.arrival);
    let arrivalEndOfDay = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      23,
      59,
      59
    );
    return arrivalEndOfDay < _now && !reservation?.isCanceled && reservation?.isCabana === false;
  });
  let canceledReservationList = reservationList.filter(
    reservation => reservation?.isCanceled && reservation?.isCabana === false
  );

  const filteredReservations = {
    upcomingReservationList: upcomingReservationList,
    pastReservationList: pastReservationList,
    canceledReservationList: canceledReservationList
  };

  return filteredReservations;
}

const reservationsReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Get Book information reducers
  switch (action.type) {
    case reservationsActionTypes.SET_RESERVATION_LAST_NAME_FIND_BY_ID:
      return {
        ...state,
        lastNameFindReservationById: action.lastName
      };
    case reservationsActionTypes.CREATE_RESERVATION_SUCCESS:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: action.createdReservation,
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList]
      };
    case reservationsActionTypes.CREATE_RESERVATION_LOADING:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList],
        isLoading: action.isLoading
      };
    case reservationsActionTypes.CREATE_RESERVATION_ERROR:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList],
        errorMessage: action.errorMessage,
        errorType: action.errorType
      };
    case reservationsActionTypes.GET_RESERVATION_BY_ID_SUCCESS:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: action.confirmedReservation,
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList],
        requestFailed: false,
        isLoading: false,
        errorMessage: null
      };

    case reservationsActionTypes.GET_RESERVATION_BY_ID_ERROR:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...INITIAL_STATE.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList],
        isLoading: false
      };

    case reservationsActionTypes.GET_RESERVATION_BY_ID_FINISH:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList],
        requestHasFinished: action.requestHasFinished
      };

    // Reservation item request succeded
    case reservationsActionTypes.GET_RESERVATION_INFO_SUCCESS:
      return {
        ...state,
        confirmedReservation: action.confirmedReservation,
        reservationList: action.reservationList,
        upcomingReservationList: action.upcomingReservationList,
        pastReservationList: action.pastReservationList,
        canceledReservationList: action.canceledReservationList,
        reservationUser: action.reservationUser,
        requestFailed: false,
        isLoading: false,
        isInitialLoading: false,
        errorMessage: null
      };
    // The request is currently running
    case reservationsActionTypes.GET_RESERVATION_INFO_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Reservation item request failed
    case reservationsActionTypes.GET_RESERVATION_INFO_ERROR:
      return {
        ...state,
        reservationList: action.reservationList,
        upcomingReservationList: [],
        pastReservationList: [],
        canceledReservationList: [],
        reservationUser: action.reservationUser,
        requestFailed: action.requestFailed,
        errorMessage: action.errorMessage,
        isLoading: false,
        isInitialLoading: false
      };
    // Reservation item request finished
    case reservationsActionTypes.GET_RESERVATION_INFO_FINISH:
      const getValidValue = (value, key) => (value !== undefined ? value : state[key]);

      const newIsLoadingValue = getValidValue(action.isLoading, 'isLoading');
      const newErrorMessageValue = getValidValue(action.errorMessage, 'errorMessage');
      const newRequestFailedValue = getValidValue(action.requestFailed, 'requestFailed');

      return {
        ...state,
        requestHasFinished: action.requestHasFinished,
        isLoading: newIsLoadingValue,
        errorMessage: newErrorMessageValue,
        requestFailed: newRequestFailedValue
      };

    case reservationsActionTypes.CREATE_RESERVATION_TEMP_USER:
      return {
        ...state,
        tempUserInfo: {
          ...action.tempUserInfo
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        canceledReservationList: [...state.canceledReservationList]
      };
    // Action to clear the errors
    case reservationsActionTypes.CLEAR_RESERVATION_ERROR:
      return {
        ...state,
        requestFailed: false,
        errorMessage: null,
        errorType: ''
      };

    // Action to set initial loading.
    case reservationsActionTypes.GET_RESERVATION_INITIAL_INFO_LOADING:
      return {
        ...state,
        isInitialLoading: action.isInitialLoading
      };

    case reservationsActionTypes.SET_RESERVATION_KEY_FIND_RESERVATION_BY_ID:
      return {
        ...state,
        reservationKeyFindReservationById: action.reservationIdKey
      };

    default:
      return state;
  }
};

export default reservationsReducer;
