// Plans action types

const plansActionTypes = {
  SET_PLANS_AVAILABILITY_DETAILS_ERROR: 'SET_PLANS_AVAILABILITY_DETAILS_ERROR',
  GET_PLANS_AVAILABILITY_INFO_SUCCESS: 'GET_PLANS_AVAILABILITY_INFO_SUCCESS',
  GET_PLANS_AVAILABILITY_INFO_ERROR: 'GET_PLANS_AVAILABILITY_INFO_ERROR',
  GET_PLANS_AVAILABILITY_INFO_LOADING: 'GET_PLANS_AVAILABILITY_INFO_LOADING',
  SET_PLANS_IS_ERROR_MODAL_ALREADY_CLOSED: 'SET_PLANS_IS_ERROR_MODAL_ALREADY_CLOSED',
  GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS: 'GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS',
  GET_PLANS_RECENT_SEARCHES_INFO_LOADING: 'GET_PLANS_RECENT_SEARCHES_INFO_LOADING',
  GET_PLANS_RECENT_SEARCHES_INFO_ERROR: 'GET_PLANS_RECENT_SEARCHES_INFO_ERROR',
  SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS: 'SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS',
  DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS: 'DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS',
  SELECT_PLANS_SUITE_FOR_RANGE_TYPE: 'SELECT_PLANS_SUITE_FOR_RANGE_TYPE',
  UNSELECT_PLANS_SUITE_FOR_RANGE_TYPE: 'UNSELECT_PLANS_SUITE_FOR_RANGE_TYPE',
  OPEN_PLANS_RATES_CALENDAR: 'OPEN_PLANS_RATES_CALENDAR',
  CLOSE_PLANS_RATES_CALENDAR: 'CLOSE_PLANS_RATES_CALENDAR',
  CLEAN_PLANS_CALENDAR_RATES_BY_STAY: 'CLEAN_PLANS_CALENDAR_RATES_BY_STAY',
  LAST_PLANS_CALENDAR_RATES_BY_STAY_SELECTION: 'LAST_PLANS_CALENDAR_RATES_BY_STAY_SELECTION',
  OPEN_PLANS_CALENDAR_RATES_BY_STAY: 'OPEN_PLANS_CALENDAR_RATES_BY_STAY',
  OPEN_PLANS_RATES_CALENDAR_RESTRICTION: 'OPEN_PLANS_RATES_CALENDAR_RESTRICTION',
  OPEN_PLANS_RATES_CALENDAR_RESTRICTION_INCREMENT: 'OPEN_PLANS_RATES_CALENDAR_RESTRICTION_INCREMENT',
  CLOSE_PLANS_RATES_CALENDAR_RESTRICTION: 'CLOSE_PLANS_RATES_CALENDAR_RESTRICTION',
  OPEN_PLANS_PAYMENT_LEAVE_MODAL: 'OPEN_PLANS_PAYMENT_LEAVE_MODAL',
  CLOSE_PLANS_PAYMENT_LEAVE_MODAL: 'CLOSE_PLANS_PAYMENT_LEAVE_MODAL',
  GET_PLANS_LOWEST_RATES_CALENDAR: 'GET_PLANS_LOWEST_RATES_CALENDAR',
  GET_PLANS_LOWEST_RATES_CALENDAR_MOBILE: 'GET_PLANS_LOWEST_RATES_CALENDAR_MOBILE',
  GET_PLANS_LOWEST_RATES_CALENDAR_ERROR: 'GET_PLANS_LOWEST_RATES_CALENDAR_ERROR',
  CLEAN_PLANS_CALENDAR_RESTRICTIONS_AND_LOWEST_RATES: 'CLEAN_PLANS_CALENDAR_RESTRICTIONS_AND_LOWEST_RATES',
  GET_PLANS_AVAILABILITY_HIGHEST_RATES: 'GET_PLANS_AVAILABILITY_HIGHEST_RATES',
  GET_PLANS_AVAILABILITY_HIGHEST_RATES_SUCCESS: 'GET_PLANS_AVAILABILITY_HIGHEST_RATES_SUCCESS',
  GET_PLANS_AVAILABILITY_HIGHEST_RATES_ERROR: 'GET_PLANS_AVAILABILITY_HIGHEST_RATES_ERROR',
  GET_PLANS_AVAILABILITY_HIGHEST_RATES_DATES_EXCEEDED_ERROR:
    'GET_PLANS_AVAILABILITY_HIGHEST_RATES_DATES_EXCEEDED_ERROR',
  SET_PLANS_APPLIED_FILTERS: 'SET_PLANS_APPLIED_FILTERS',
  SET_PLANS_SELECTED_SUITE_NAME_FOR_CALENDAR: 'SET_PLANS_SELECTED_SUITE_NAME_FOR_CALENDAR',
  GET_PLANS_AVAILABILITY_RANGE: 'GET_PLANS_AVAILABILITY_RANGE',
  GET_PLANS_AVAILABILITY_RANGE_SUCCESS: 'GET_PLANS_AVAILABILITY_RANGE_SUCCESS',
  GET_PLANS_AVAILABILITY_RANGE_ERROR: 'GET_PLANS_AVAILABILITY_RANGE_ERROR',
  GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING: 'GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING',
  GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_ERROR: 'GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_ERROR',
  GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_RESET: 'GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_RESET',
  SET_PLANS_LATEST_J_VERSION_EVENT_ERROR: 'SET_PLANS_LATEST_J_VERSION_EVENT_ERROR',
  SET_PLANS_LATEST_H_VERSION_EVENT_ERROR: 'SET_PLANS_LATEST_H_VERSION_EVENT_ERROR',
  GET_PLANS_ITEM_QUANTITY_TYPE: 'GET_PLANS_ITEM_QUANTITY_TYPE',
  GET_PLANS_ITEM_QUANTITY_SUCCESS: 'GET_PLANS_ITEM_QUANTITY_SUCCESS',
  GET_PLANS_ITEM_QUANTITY_ERROR: 'GET_PLANS_ITEM_QUANTITY_ERROR',
  TOGGLE_PLANS_EMAIL_CAMPAIGNS: 'TOGGLE_PLANS_EMAIL_CAMPAIGNS',
  CLEAR_PLANS_ALL_AVAILABILITY_DATA: 'CLEAR_PLANS_ALL_AVAILABILITY_DATA',
  SET_PLANS_IS_CALIFORNIA_RESIDENT: 'SET_PLANS_IS_CALIFORNIA_RESIDENT',
  CLEAN_PLANS_IS_CALIFORNIA_RESIDENT: 'CLEAN_PLANS_IS_CALIFORNIA_RESIDENT',
  SET_PLANS_QUALIFYING_ID: 'SET_PLANS_QUALIFYING_ID',
  GET_PLANS_QUALIFYING_ID_HAS_ERROR: 'GET_PLANS_QUALIFYING_ID_HAS_ERROR',
  SET_PLANS_TAB_INDEX_BY_EMAIL_CAMPAIGN: 'SET_PLANS_TAB_INDEX_BY_EMAIL_CAMPAIGN',
  SET_PLANS_IS_BOOKING_WIDGET_FOCUSED: 'SET_PLANS_IS_BOOKING_WIDGET_FOCUSED',
  SET_PLANS_SORTING_ORDER: 'SET_PLANS_SORTING_ORDER',
  SET_PLANS_SUITE_CODE_FILTER: 'SET_PLANS_SUITE_CODE_FILTER',
  SET_PLANS_IS_CART_OPEN_CLOSE: 'SET_PLANS_IS_CART_OPEN_CLOSE',
  SET_PLANS_IS_CART_OPEN_CLOSE_CLEAN: 'SET_PLANS_IS_CART_OPEN_CLOSE_CLEAN',
  SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED: 'SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED',
  SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED_CLEAN: 'SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED_CLEAN'
};

export default plansActionTypes;
