import { isDevEnvironment } from './env';

/* In here we store the Google Optimize global variables which are the parameters
   for the A/B testing
*/
export const optimizelyReverseGeoOff = window.optimizelyReverseGeoOff || false;

export const optimizelyChargePackagesAtCheckout = window.chargePackagesAtCheckout || false;

export const optimizelyPackageAemBestValue = window.packageAemBestValue || false;

export const optimizelyHideSoldOutSuites = window.hideSoldOutSuites || false;

export const optimizelyNewButtonsVariants = window.newButtonsVariants || false;

// the window variable is to be defined.
export const optimizelyDealsHeader = window.dealHeaderVariant || false;

export const optimizelyConsecutiveDealFetching = window.dealFetchingVariant || false;

export const optimizelyAttractionPassesMessaging = window.optimizelyAttractionPassesMessaging || false;

export const optimizelyDisableRateCalendar = window.optimizelyDisableRateCalendar || false;

export const optimizelyHideActivityTabs = window.optimizelyHideActivityTabs || false;

export const optimizelyHideFamilyTab = window.optimizelyHideFamilyTab || false;

export const optimizelyHideMOREFUNBanner = window.optimizelyHideMOREFUNBanner || false;

export const optimizelyFixedPropertyBookCTA = window.optimizelyFixedPropertyBookCTA || false;

export const optimizelyShowBookCTAOnScroll = window.optimizelyShowBookCTAOnScroll || false;

export const optimizelyShowPaymentSignIn = window.optimizelyShowPaymentSignIn || false;

export const optimizelyCalendarRangeWithRateA = window.optimizelyCalendarRangeWithRateA || false;

export const optimizelyCalendarRangeWithRateB = window.optimizelyCalendarRangeWithRateB || false;

export const optimizelyCalendarRangeWithRateC = window.optimizelyCalendarRangeWithRateC || false;

export const optimizelyCalendarRangeWithRateD = window.optimizelyCalendarRangeWithRateD || false;

export const lateCheckoutAndEarlyAccessWaterParkModal = window.lateCheckoutAndEarlyAccessWaterParkModal || false;

export const optimizelyHideLoyalty = window.optimizelyHideLoyalty || false;

export const optimizelyDaypassPackagesSilver = window.optimizelyDaypassPackagesSilver || false;

export const optimizelyDaypassPackagesGold = window.optimizelyDaypassPackagesGold || false;

export const optimizelyDaypassPackagesPlatinum = window.optimizelyDaypassPackagesPlatinum || false;

export const optimizelyRateCalendarNightPrice = window.optimizelyRateCalendarNightPrice || false;

export const optimizelyHideVoyagersBannerMobile = window.optimizelyHideVoyagersBannerMobile || false;

export const optimizelyDisplayPricePerPersonInSuiteCard = window.optimizelyDisplayPricePerPersonInSuiteCard || false;

export const TESTS_DICTIONARY = {
  optimizelyForceOpera: 'optimizelyForceOpera',
  optimizelySuiteCardB: 'optimizelySuiteCardB',
  optimizelySuiteFilterIcons: 'optimizelySuiteFilterIcons',
  optimizelyPoconosTowerOption: 'optimizelyPoconosTowerOption',
  optimizelyAddParkingFee1: 'optimizelyAddParkingFee1',
  optimizelyAddParkingFee2: 'optimizelyAddParkingFee2',
  optimizelyAddParkingFee3: 'optimizelyAddParkingFee3',
  // COMMENTED BY HOTFIX 51917 UNTIL APRIL 1 2024
  // optimizelyBundleDealModalB: 'optimizelyBundleDealModalB',
  // optimizelyBundleDealModalC: 'optimizelyBundleDealModalC',
  optimizelyWaterParkPassesWarning: 'optimizelyWaterParkPassesWarning',
  optimizelyTwoImagesSuiteCard: 'optimizelyTwoImagesSuiteCard',
  optimizelyNewFilters: 'optimizelyNewFilters',
  optimizelyPaymentWarnings: 'optimizelyPaymentWarnings',
  optimizelyKeepPackagesOnSuiteChange: 'optimizelyKeepPackagesOnSuiteChange',
  optimizelySuperscriptStaySummarySuite: 'optimizelySuperscriptStaySummarySuite',
  optimizelyPromoClarity: 'optimizelyPromoClarity',
  optimizelyTruncateCentsVillas: 'optimizelyTruncateCentsVillas',
  optimizelyAttractionsPassesSelection: 'optimizelyAttractionsPassesSelection',
  optimizelyPasswordValidationStyle: 'optimizelyPasswordValidationStyle',
  optimizelyHierarchyFlip: 'optimizelyHierarchyFlip',
  optimizelyResortFeeInSuiteCard: 'optimizelyResortFeeInSuiteCard',
  optimizelyNewCardNonPoconos: 'optimizelyNewCardNonPoconos',
  optimizelySecureCheckout: 'optimizelySecureCheckout',
  optimizelyLowestSuiteRates: 'optimizelyLowestSuiteRates',
  optimizelyCartFlow: 'optimizelyCartFlow',
  optimizelyAppleGoolePayOrder: 'optimizelyAppleGoolePayOrder',
  optimizelyDisplayResortFeeWithTotalInSuiteCardH: 'optimizelyDisplayResortFeeWithTotalInSuiteCardH',
  optimizelyDisplayResortFeeWithTotalInSuiteCardJ: 'optimizelyDisplayResortFeeWithTotalInSuiteCardJ',
  optimizelyExpressCheckout: 'optimizelyExpressCheckout',
  optimizelyShowDiningPageInTheUFFFlow: 'optimizelyShowDiningPageInTheUFFFlow'
};

/**
 * This is for tests that are located on multiple pages or components.
 * This array is to be empty by default.
 */
const MULTIPLE_TESTS_ACTIVE_LISTS = [];

/**
 *
 * @param {*} testName This is the name of the global variable that is set in Optimizely
 * @param {*} developMode This is a boolean that is set to true if you want to see the test in develop mode
 * @returns
 */
export const getTestValueAB = (testName, developMode = false) => {
  if (developMode) {
    return true;
  }
  if (isDevEnvironment && MULTIPLE_TESTS_ACTIVE_LISTS.includes(testName)) {
    return true;
  }

  return window[testName] || false;
};
