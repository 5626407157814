// Lodge Config action types

const lodgeConfigActionTypes = {
  ID_ME_DATA_PARAMETERS: 'ID_ME_DATA_PARAMETERS',
  LOADING_LODGE_CONFIG: 'LOADING_LODGE_CONFIG',
  ERROR_LODGE_CONFIG_LOADING: 'ERROR_LODGE_CONFIG_LOADING',
  CLEAR_LODGE_CONFIG_LOADING: 'CLEAR_LODGE_CONFIG_LOADING',
  SUCCESS_LODGE_CONFIG_LOADING: 'SUCCESS_LODGE_CONFIG_LOADING',
  LOADING_LODGE_CONFIG_PLAN: 'LOADING_LODGE_CONFIG_PLAN',
  ERROR_LODGE_CONFIG_LOADING_PLAN: 'ERROR_LODGE_CONFIG_LOADING_PLAN',
  CLEAR_LODGE_CONFIG_LOADING_PLAN: 'CLEAR_LODGE_CONFIG_LOADING_PLAN',
  SUCCESS_LODGE_CONFIG_LOADING_PLAN: 'SUCCESS_LODGE_CONFIG_LOADING_PLAN',
  LOADING_LODGE_CONFIG_USER_SETTINGS: 'LOADING_LODGE_CONFIG_USER_SETTINGS',
  ERROR_LODGE_CONFIG_USER_SETTINGS: 'ERROR_LODGE_CONFIG_USER_SETTINGS',
  CLEAR_LODGE_CONFIG_USER_SETTINGS: 'CLEAR_LODGE_CONFIG_USER_SETTINGS',
  SUCCESS_LODGE_CONFIG_USER_SETTINGS: 'SUCCESS_LODGE_CONFIG_USER_SETTINGS',
  LOADING_LODGE_CONFIG_CREDIT_PACKAGE: 'LOADING_LODGE_CONFIG_CREDIT_PACKAGE',
  ERROR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE: 'ERROR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE',
  CLEAR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE: 'CLEAR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE',
  SUCCESS_LODGE_CONFIG_LOADING_CREDIT_PACKAGE: 'SUCCESS_LODGE_CONFIG_LOADING_CREDIT_PACKAGE',
  LOADING_LODGE_CONFIG_CMP: 'LOADING_LODGE_CONFIG_CMP',
  ERROR_LODGE_CONFIG_LOADING_CMP: 'ERROR_LODGE_CONFIG_LOADING_CMP',
  CLEAR_LODGE_CONFIG_LOADING_CMP: 'CLEAR_LODGE_CONFIG_LOADING_CMP',
  SUCCESS_LODGE_CONFIG_LOADING_CMP: 'SUCCESS_LODGE_CONFIG_LOADING_CMP',
  LOADING_LODGE_CONFIG_PAYMENT: 'LOADING_LODGE_CONFIG_PAYMENT',
  ERROR_LODGE_CONFIG_LOADING_PAYMENT: 'ERROR_LODGE_CONFIG_LOADING_PAYMENT',
  CLEAR_LODGE_CONFIG_LOADING_PAYMENT: 'CLEAR_LODGE_CONFIG_LOADING_PAYMENT',
  SUCCESS_LODGE_CONFIG_LOADING_PAYMENT: 'SUCCESS_LODGE_CONFIG_LOADING_PAYMENT',
  LOADING_LODGE_CONFIG_LEADGEN: 'LOADING_LODGE_CONFIG_LEADGEN',
  ERROR_LODGE_CONFIG_LOADING_LEADGEN: 'ERROR_LODGE_CONFIG_LOADING_LEADGEN',
  CLEAR_LODGE_CONFIG_LOADING_LEADGEN: 'CLEAR_LODGE_CONFIG_LOADING_LEADGEN',
  SUCCESS_LODGE_CONFIG_LOADING_LEADGEN: 'SUCCESS_LODGE_CONFIG_LOADING_LEADGEN',
  RESET_LODGE_CONFIG: 'RESET_LODGE_CONFIG',
  LOADING_LODGE_CONFIG_CART: 'LOADING_LODGE_CONFIG_CART',
  ERROR_LODGE_CONFIG_LOADING_CART: 'ERROR_LODGE_CONFIG_LOADING_CART',
  CLEAR_LODGE_CONFIG_LOADING_CART: 'CLEAR_LODGE_CONFIG_LOADING_CART',
  SUCCESS_LODGE_CONFIG_LOADING_CART: 'SUCCESS_LODGE_CONFIG_LOADING_CART',
  LOADING_LODGE_CONFIG_BOOKING_WIDGET: 'LOADING_LODGE_CONFIG_BOOKING_WIDGET',
  ERROR_LODGE_CONFIG_LOADING_BOOKING_WIDGET: 'ERROR_LODGE_CONFIG_LOADING_BOOKING_WIDGET',
  CLEAR_LODGE_CONFIG_LOADING_BOOKING_WIDGET: 'CLEAR_LODGE_CONFIG_LOADING_BOOKING_WIDGET',
  SUCCESS_LODGE_CONFIG_LOADING_BOOKING_WIDGET: 'SUCCESS_LODGE_CONFIG_LOADING_BOOKING_WIDGET',
  LOADING_LODGE_CONFIG_PARTY_BOOKING_WIDGET: 'LOADING_LODGE_CONFIG_PARTY_BOOKING_WIDGET',
  ERROR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET: 'ERROR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET',
  CLEAR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET: 'CLEAR_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET',
  SUCCESS_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET: 'SUCCESS_LODGE_CONFIG_LOADING_PARTY_BOOKING_WIDGET'
};

export default lodgeConfigActionTypes;
