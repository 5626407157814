import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the User Account endpoints on Tioga
 *
 * @class UserAccountApiClient
 * @extends {GWRApiClient}
 */
export default class UserAccountApiClientTioga extends GWRApiClient {
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v1`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_USERACCOUNT_ENDPOINT_TIOGA, apiVersion);

    super(urlAux, token);
  }

  /**
   * @function
   * Get user informstion based on token
   */
  async getUserUpdatedPointsAndOffers() {
    const urlRequest = `${this.url}/loyalty/balance`;
    let request = await super.getApiCallAuth(urlRequest);
    return request;
  }

  /**
   * @function
   * Get user point history information based on token and page
   * @param {requestObject: {page: number}} requestObject
   */
  async fetchUserPointsHistory(requestObject) {
    const urlRequest = `${this.url}/loyalty/history`;
    let request = await super.getApiCallAuth(urlRequest, requestObject.queryParams);
    return request;
  }

  /**
   * @function
   * Update user settings for my profile and my preferences pages
   * @param requestObject
   */
  async updateUserSettings(requestObject) {
    const urlRequest = `${this.url}`;
    let request = await super.patchApiCallAuth(urlRequest, requestObject);
    return request;
  }
}
