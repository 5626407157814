export const ASSETS_DOMAIN = 'https://cdn.greatwolf.com';

export const ASSETS_LOCATIONS = {
  ROOT: '{ASSETS_DOMAIN}/{FILE_NAME}',
  CONTENT: '{ASSETS_DOMAIN}/content/{FILE_NAME}',
  GIFS: '{ASSETS_DOMAIN}/content/gifs/{FILE_NAME}',
  JPG: '{ASSETS_DOMAIN}/content/jpg/{FILE_NAME}',
  JS: '{ASSETS_DOMAIN}/content/js/{FILE_NAME}',
  PNG: '{ASSETS_DOMAIN}/content/png/{FILE_NAME}',
  SVG: '{ASSETS_DOMAIN}/content/svg/{FILE_NAME}',
  WEBP: '{ASSETS_DOMAIN}/content/webp/{FILE_NAME}',
  VIDEO: '{ASSETS_DOMAIN}/content/video/{FILE_NAME}',
  ANIMATION: '{ASSETS_DOMAIN}/content/animations/{FILE_NAME}'
};

export const getAssetsUrl = (url, file) => {
  return url?.replace('{ASSETS_DOMAIN}', ASSETS_DOMAIN)?.replace('{FILE_NAME}', file);
};

export const AVAILABILITY_KEY_ERROR_TYPE = 'CRS006';
export const NO_INVENTORY_ERROR_TYPE = 'CRS009';

export const CHECKIN_TIME = '4 p.m.';
export const SITE_NIAGON = 'NIAGON';
export const SITE_POCOPA = 'POCOPA';
export const SITE_CONCORD = 'CONCNC';
export const SITE_GRAPEVINE = 'GRAPTX';
export const SITE_MANTECA = 'MANTCA';
export const SITE_ANAHEIM = 'SOUTCA';
export const SUITE_RATE = 'Suite Rate';
export const RESERVATION_STATUS = {
  canceled: 'CANCELED'
};

export const LATE_CHECKOUT_PREFIXES = {
  code: 'LCO',
  itemCode: 'LCO'
};

export const LATE_CHECKOUT_PACKAGES_CODES = ['2LCOSTAN', '2LCOTHEM', '2LCOPREM'];

export const HOWL_N_LEARN_CODE = 'HOWLEARN';

export const CHECKOUT_TIMES = {
  AM_11: '11 a.m.',
  PM_1: '1 p.m.',
  PM_2: '2 p.m.'
};

// Analytics related constants
export const EVENT_PARAMS = {
  fbEventId: 'x-fb-event_id'
};

export const PRODUCT_SCOPES = {
  suiteCheckout: 'suite-checkout',
  postPurchase: 'post-purchase',
  productImpressions: 'product-impressions',
  productClick: 'product-click',
  addToCart: 'add-to-cart',
  standardPurchase: 'standard purchase'
};

export const EVENT_CATEGORIES = {
  GTM: {
    transaction: 'transaction',
    purchasePackage: 'purchasePackage',
    purchaseSuite: 'purchaseSuite',
    checkoutOption: 'checkoutOption',
    modifyError: 'modify-error',
    postPurchasePackage: 'postPurchasePackage',
    fbPurchase: 'fbPurchase',
    signIn: 'SignIn',
    passwordChange: 'PasswordChange',
    signUp: 'SignUp',
    leadgen: 'LeadGen',
    checkoutStep: 'checkout-step',
    addToCart: 'add-to-cart',
    removeFromCart: 'remove-from-cart',
    postPurchase: 'post-purchase',
    packages: 'packages',
    search: 'search',
    rateCalendar: 'rate-calendar',
    daypassPackagePurchase: 'daypassPackagePurchase',
    passPackagePurchasesVariationA: 'passPackagePurchasesVariationA',
    passPackagePurchasesVariationB: 'passPackagePurchasesVariationB'
  },
  INTERACTIONS: {
    postReservation: 'InteractionStudioReservation'
  }
};

export const GTM_EVENT = {
  NAME: {
    websiteVitals: 'WebsiteVitals',
    daypassDiningPackagePurchase: 'daypassDiningPackagePurchase',
    passPurchase: 'passPackagePurchase'
  },
  ACTION: {
    signInAttempt: 'SignInAttempt',
    signInSuccess: 'SignInSuccess',
    signInFailure: 'SignInFailure',
    signUpAttempt: 'SignUpAttempt',
    signUpFailure: 'SignUpFailure',
    signUpAttemptRaF25: 'SignUpAttemptReferral25Group',
    signUpAttemptRaF50: 'SignUpAttemptReferral50Group',
    signUpSuccess: 'SignUpSuccess',
    signUpSuccessRaF25: 'SignUpSuccessReferral25Group',
    signUpSuccessRaF50: 'SignUpSuccessReferral50Group',
    leadGenSuccess: 'LeadGenSuccess',
    passwordChangeRequest: 'PasswordChangeRequest',
    passwordChangeAttempt: 'PasswordChangeAttempt',
    passwordChangeSuccess: 'PasswordChangeSuccess',
    passwordChangeFailure: 'PasswordChangeFailure',
    openRateCalendar: 'OpenRateCalendar',
    closeRateCalendar: 'CloseRateCalendar',
    daypassPurchaseVersionB: 'DaypassPurchaseVersionB',
    daypassPurchaseVersionC: 'DaypassPurchaseVersionC',
    daypassPurchaseVersionD: 'DaypassPurchaseVersionD'
  },
  LABEL: {
    greatWolf: 'Great Wolf',
    facebook: 'Facebook',
    google: 'Google',
    apple: 'Apple',
    greatWolfLowerCase: 'great wolf'
  },
  PURCHASE_LOCATION: {
    post_package_purchase: 'post package purchase',
    standard_purchase: 'standard purchase',
    pre_checkin: 'pre checkin'
  }
};

export const DATE_FORMATS = {
  default: 'YYYY-MM-DD',
  defaultWithSlash: 'YYYY/MM/DD',
  monthDayYearLeadingZeros: 'MM/DD/YYYY',
  displayFormat: 'LT',
  formatDayMoreDetailed: 'ddd, MMM DD',
  formatMonthDate: 'MMM DD',
  formatMonthLetterDate: 'MMMM DD',
  formatDayEvenMoreDetailed: 'dddd, MMMM, D, YYYY',
  monthDayFormat: 'MM/DD',
  dayMonthYearFormat: 'DD/MM/YYYY',
  monthLetterDayYear: 'MMM. DD, YYYY',
  formatDay: 'DD',
  numberFormat: 'YYYYMMDDHHmmss',
  monthName: 'MMMM',
  weekMonthYear: 'ddd, MMM Do, YYYY',
  monthDayYear: 'MMM DD, YYYY',
  dayMonthShortYearFormat: 'MM/DD/YY',
  shortDateFormatWithWeekday: 'ddd, MMM D, YYYY',
  dayNameMonthDayYear: 'ddd. MMM D, YYYY',
  monthLongDayCommaYear: 'MMMM D, YYYY',
  monthLongDay: 'MMMM D'
};

export const TALL_MODAL = 'tall-modal';
export const SHORT_MODAL = 'short-modal';

export const NO_DATE = '1901-01-01';

// Suite image URL.
export const LATE_CHECKOUT_IMAGE_URL =
  '/content/dam/greatwolf/sites/www/locations/master/Deals/characters-sleeping.png';

export const CONFETTI_ANNIMATION_URL = getAssetsUrl(ASSETS_LOCATIONS.ANIMATION, 'colorful-confetti.json');

export const PRIVACY_POLICY = `/terms#privacy-policy`;
export const CALIFORNIA_POLICY = '/your-california-privacy-rights#nofi';
export const TERMS_AND_CONDITIONS_SUFFIX = `policies/booking-policy`;
export const TERMS_AND_CONDITIONS_ACCOUNT_CREATION = '/terms';

export const MAX_GUESTS_COUNT = 8;

export const DAY_PASSES_TYPE = {
  halfDay: 'WPHLF',
  fullDay: 'WPFULL',
  roomType: 'WPPASS'
};

export const DAY_PASSES_ROOM_TYPE = {
  WPHLF: 'half',
  WPFULL: 'full'
};

export const DAY_PASS_QID_REQUIRED = 'QIDREQUIRED';

export const CABANAS_CODES_TYPES = {
  indoor: 'CBINDR',
  outdoor: 'CBOTDR',
  group: 'CA'
};

export const PACKAGES_MESSAGES = {
  packageNotAvailable: 'Package Not Available'
};

export const PACKAGE_TYPE = {
  dining: 'dining',
  pass: 'Pass',
  activity: 'activity',
  birthday: 'Birthday',
  cabanasAndFlexTrip: 'favorite',
  main: 'main',
  attractions: 'attractions',
  flexTrip: 'FLEXTRIP',
  lateCheckOut: 'lco',
  family: 'Family',
  bundle: 'bundle',
  default: 'Default'
};

export const PACKAGE_CHARGE_TYPE = {
  personPerDay: 'person/day',
  byPackage: 'by package'
};

export const PACKAGES_CATEGORY = {
  dining: 'Dining',
  activities: 'Activities'
};

// Add only those currency code which we want to show as a price text
export const CURRENCY_CODE = {
  CAD: 'CAD' // currency code for canadian dollar
};

export const RECOMMENDED_FOR_YOU = 'REC_FOR_YOU';

export const MASK = '(000) 000-0000';
export const PHONE_MASK_REGEX = /\(|\)|-|\s/g;
export const ONLY_DIGITS_REGEX = /\D/g;
export const PHONE_MASK_FOR_MASKED_FIELD = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const OFFER_CODE_TYPE = {
  group: 'GROUP'
};

export const GUARANTEE_TYPE = {
  advancePayment: 'ADV',
  checkInPayment: 'CCG'
};

// Type of response, success or error
export const NOTIFICATION_TYPE = {
  success: 'success',
  failed: 'error'
};

export const NEW_DAYS_TO_ADD = 5; // Days to add when an old date is entered in plan

export const SUITE_TYPES = {
  unknown: 'UNKNOWN',
  standard: 'standard',
  themed: 'themed',
  premium: 'premium',
  condo: 'condo'
};

export const BACKEND_PAYMENT_TYPES = {
  Affirm: 'AF'
};

export const PACKAGES_TITLE = {
  inclusiveMeal: 'Inclusive Meal'
};

/**
 * If any package has packageGrouo equal toa ny of these values, it should be
 * grouped by the packageGroup instead of the packageCode.
 *
 * Example:
 * const packages = [
 *   {
 *      "description": "B/L Breakfast FOOD",
 *      "packageCode": "BFSTFOOD2",
 *      "packageGroup": "BKFTLNCH",
 *   },
 *   {
 *      "description": ""B/L Breakfast GRAT",
 *      "packageCode": "BFSTFOOD2",
 *      "packageGroup": "BKFTLNCH",
 *   }
 * ]
 *
 * These packages belong to 'BKFTLNCH' packageCode hence, they should be grouped
 * into BKFTLNCH code to be able to calculate rates.
 */
export const PACKAGES_GROUPS = [
  'ALLMEALS',
  'BFSTNLNCH',
  'BKFTLNCH',
  'BKFTLNCH2',
  'BFSTNLNCH',
  'ALLMEALZ',
  'ALLMEALS2',
  'HWLHUNGER2'
];

/**
 * Rate Calendar Day Status Types
 */
export const RATE_CAL_DAY_STATUS = {
  available: 'AVAILABLE',
  checkout: 'CHECKOUT',
  unavailable: 'UNAVAILABLE'
};

/**
 * Sorting types
 * @enum {string}
 */
export const SORTING_CRITERIA = {
  descending: 'desc',
  ascending: 'asc',
  recommendedForYou: 'rec'
};

/** Time used to display the Lead Gen modal */
export const LEADGEN_MODAL_DISPLAY_TIME = 30000;

export const ProgressBarStatus = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  ACTIVE: 'active'
};

/**Mobile app download page url */
export const MobileAppPageUrl = '/mobile-app';

/** Customer contact center phone number */
export const CUSTOMER_CONTACT_CENTER_NUM = '800-905-9653';

export const PERSONALIZATION_RULES = {
  interactions: 4
};

export const PERSONALIZATION_PACKAGE_RULES = {
  interactions: 1
};

export const APPLE_SIGN_IN_SUCCESS_EVT = 'AppleIDSignInOnSuccess';
export const APPLE_SIGN_IN_FAILURE_EVT = 'AppleIDSignInOnFailure';

export const ROOM_RATES_ERROR_TITLE = 'An error has occurred';
export const ROOM_RATES_ERROR_MSG = 'Sorry, Rooms for selected dates are not available, please check other dates';
export const ROOM_RATES_DATES_EXCEEDED_ERROR_MSG =
  'Sorry, we are unable to process reservations of longer than one month. Please go back and try again.';
export const SUITE_ERROR_MSG = 'SOME ERRORS OCCURRED';

export const COSTSUMMARY_TOOLTIP = {
  sustainabilityFee:
    'Great Wolf Lodge Arizona is located in the Salt River Pima - Maricopa Indian Community and is subject to a Sustainability Fee charged by SRPMIC equal to 3% of sales. SRPMIC allocates these revenues to support its seven generation stewardship and related initiatives. Please see our FAQ page for further details.',
  resortFee:
    'The Resort Fee includes services that are often charged individually, including water park amenities life jackets and towels, resort-wide wireless internet, virtual concierge service, in-room amenities such as a coffee maker and mini-fridge, and perks unique to Great Wolf Lodge like complimentary arts & craft projects provided during select kids’ entertainment programs.',
  resortFeeJVersion:
    'The Resort Fee includes services that are often charged individually, including resort-wide wireless internet, virtual concierge service, in-room amenities such as a coffee maker and mini-fridge, and perks unique to Great Wolf Lodge like arts & craft projects provided during select kids’ entertainment programs.'
};

//Interaction Studio will track a user's behavior with help of these fixed class names
export const IS_CLASSES = {
  packageContainer: 'sfis-package-container',
  packagePrice: 'sfis-package-price',
  suiteContainer: 'sfis-suite-container',
  suiteDetails: 'sfis-suite-details',
  roomPrice: 'sfis-suite-price'
};

export const CHECK_IN_STATUS = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  SUCCESSFUL: 'successful',
  INVALID: 'invalid'
};

//Some packageCodes aren't supposed to show person by price labels
export const PACKAGES_WITHOUT_CHARGE_TYPE = ['BFSTFOOD', 'BFSTFOOD1', 'BREAKFAST'];

export const USER_PATCH_OPERATIONS = {
  ADD: 'add',
  EDIT: 'replace',
  REMOVE: 'remove'
};

export const SUPPORTED_COUNTRIES = {
  UnitedStates: 'US',
  Canada: 'CA'
};

export const VALID_COUNTRIES = [SUPPORTED_COUNTRIES.UnitedStates, SUPPORTED_COUNTRIES.Canada];

export const TOOLTIP_DIRECTION = {
  right: 'right',
  left: 'left'
};

export const TOOLTIP_POSITION = {
  top: 'top',
  topLeft: 'top-left',
  topRight: 'top-right',
  bottom: 'bottom',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right'
};

export const TOOLTIP_POSITIONS = {
  tops: [TOOLTIP_POSITION.top, TOOLTIP_POSITION.topLeft, TOOLTIP_POSITION.topRight],
  bottoms: [TOOLTIP_POSITION.bottom, TOOLTIP_POSITION.bottomLeft, TOOLTIP_POSITION.bottomRight]
};

export const PAYMENT_METHODS_ICONS = 'https://cdn.greatwolf.com/content/png/';

export const STATIC_CDN = 'https://cdn.greatwolf.com/content/png/';

export const TAXES = 'Taxes';
export const PARKING_FEE = 'Parking Fee';
export const RESORT_FEE = 'Resort Fee';
export const SUSTAINABILITY_FEE = 'Sustainability Fee';

export const CALENDAR_RESTRICTIONS_TYPES = {
  CLOSE: 'CLOSE',
  CLOSE_TO_ARRIVAL: 'CLOSE_TO_ARRIVAL',
  CLOSE_TO_DEPARTURE: 'CLOSE_TO_DEPARTURE',
  MIN_LENGTH_OF_STAY_ON_ARRIVAL: 'MIN_LENGTH_OF_STAY_ON_ARRIVAL',
  MIN_LENGTH_OF_STAY: 'MIN_LENGTH_OF_STAY'
};

export const SUITE_AVAILABILITY_TYPES = {
  AVAILABLE_TYPE: 'AVAILABLE_TYPE',
  UNAVAILABLE_TYPE: 'UNAVAILABLE_TYPE'
};

export const PARKING_PACKAGE_CODE = 'PARKING';

export const CONNECTICUT = 'MASHCT';

export const ROOM_RATE_TYPE = {
  roomOnlyWithoutWaterParkPass: 'ROOM_ONLY',
  roomWithWaterParkPass: 'WITH_WP'
};

export const PAYMENT_ERROR_MESSAGES = {
  default: 'Oops - we had a problem with processing the information.',
  OWS_SYSTEM_ERROR: `Unfortunately, there is been an error processing your credit card. Please contact our call center at ${CUSTOMER_CONTACT_CENTER_NUM} in order to complete your reservation`,
  CREDIT_CARD_AUTHORIZATION_FAILED: 'Credit card authorization has failed. Please check the numbers and try again.',
  PAYPAL_AUTHORIZATION_FAILED:
    'PayPal authorization has failed. Please select a different payment method and try again.',
  INVALID_CREDIT_CARD_EXPIRATION: 'The credit card expiration date is invalid. Please check the numbers and try again.',
  INVALID_RATE_CODE_FOR_PROMOTION_CODE:
    'The Offer Code you have entered does not match the Rate Code, this may be caused by multiple browser tabs opened to the Great Wolf website. Please close additional tabs and try your booking again.',
  RATE_CODE_NOT_AVAILABLE:
    'The Offer Code you have entered is no longer valid, please visit our deals page to find our latest offers.',
  ROOM_UNAVAILABLE:
    'We apologize for the inconvenience, but the room you are trying to book is no longer available, please go back and select another room type.',
  RATES_MISMATCH:
    'We apologize for the inconvenience, but the rates of the room you are trying to book has changed, please note cost summary has been updated with the new rates',
  AVAILABILITY_KEY_NOT_FOUND:
    'Booking availability has expired. To proceed with your booking, please return to the suites page and start again.',
  NO_INVENTORY_FOR_SELECTED_SUITE:
    "We're sorry—the suite you are attempting to book is no longer available. Please select a different suite from the available options."
};
