// Lodge Config Selectors

export const getLoyaltyEnabledLocation = state => {
  return state.isLoyaltyEnabled;
};

export const getOffersEnabledLocation = state => {
  return state.isOffersEnabled;
};

export const getPointsMultiplier = state => {
  return state.loyaltyPointsMultiplier;
};

export const getReferralEnabledLocation = state => {
  return state.isLoyaltyReferralEnabled;
};

export const getDiningPackagesEnhancedUIActive = state => {
  return state.isDiningPackagesEnhancedUIEnabled;
};

export const getDynamicTileActive = state => {
  return state.isDynamicTileEnabled;
};

export const getHideOnlineCheckinText = state => {
  return state.hideOnlineCheckinText;
};

export const getaffirmPubKey = state => {
  return state.affirmPubKey;
};

export const getshowCaliforniaResortFeeVariation = state => {
  return state.showCaliforniaResortFeeVariation;
};

export const getPricePerWaterPass = state => {
  return state.pricePerWaterPass;
};

export const getFilters = state => {
  return JSON.parse(state.filters);
};

export const getAdditionalPersonAmount = state => {
  return state.additionalPersonAmount;
};

export const getIsHighestRatesEnabled = state => {
  return state.isHighestRatesEnabled;
};

export const getIsRateCalEnabled = state => {
  return state.isRateCalEnabled;
};

export const getUpdateSearchFullPageRefresh = state => {
  return state.updateSearchFullPageRefresh;
};

export const getFetchRestrictionsEnabled = state => {
  return state.isFetchRestrictionsEnabled;
};

export const getPerNightParkingFee1 = state => {
  return state.perNightParkingFee1;
};

export const getPerNightParkingFee2 = state => {
  return state.perNightParkingFee2;
};

export const getPerNightParkingFee3 = state => {
  return state.perNightParkingFee3;
};

export const getParkingFeeStartDate = state => {
  return state.parkingFeeStartDate;
};

export const getParkingFeeEndDate = state => {
  return state.parkingFeeEndDate;
};

export const getClientCardTokenization = state => {
  return state.clientCardTokenization;
};

export const getCreditCardAuthUrl = state => {
  return state.creditCardAuthUrl;
};

export const getNewPricingRulesLodgeList = state => {
  return JSON.parse(state.newPricingRulesLodgeList);
};

export const getPaymentMethodsAllowed = state => {
  return {
    creditEnabled: state.creditEnabled,
    affirmEnabled: state.affirmEnabled,
    applePayEnabled: state.applePayEnabled,
    googlePayEnabled: state.googlePayEnabled,
    payPalEnabled: state.payPalEnabled,
    amexEnabled: state.amexEnabled
  };
};

export const getShouldLeadGenPop = state => {
  return state.shouldLeadGenPop;
};

export const getParkingCallout = state => {
  return state.parkingFeeCallout;
};

export const getParkingCalloutTitle = state => {
  return state.parkingFeeCalloutTitle;
};

export const getParkingCalloutDescription = state => {
  return state.parkingFeeCalloutDescription;
};

export const getParkingFeeWarning = state => {
  return state.bookingSummaryParkingFee;
};

export const getUffTileEnabled = state => {
  return state.uffTileEnabled;
};

export const getIsLoyaltyPointsExpirationOnProfileEnabled = state => {
  return state.isLoyaltyPointsExpirationOnProfileEnabled;
};

export const getIsCartActive = state => {
  return state.isCartActive;
};

export const getIsRoomUnbundledRateWPAvailable = state => {
  return state.isRoomUnbundledRateWPAvailable;
};

export const getIsNewPatchEndpoint = state => {
  return state.newPatchEndpoint;
};

export const getMaxOccupancy = state => state.maxOccupancy;

export const getBeginReservationDate = state => state.beginReservationsDate;

export const getLoyaltyPointsToEarn = state => {
  return state.loyaltyPointsToEarn;
};

export const getLoyaltyEarnThresholdNights = state => {
  return state.loyaltyEarnThresholdNights;
};

export const getShowPackagesInAddGuestsModal = state => {
  return state.showPackagesInAddGuestsModal;
};

export const getIsManageYourReservationEnabled = state => {
  return state.isManageYourReservationEnabled;
};

export const getSuitePriceIncludedTaxesAndFees = state => {
  return state.suitePriceIncludedTaxesAndFees;
};

export const getTaxAndFeesLabels = state => {
  return state.taxAndFeeLabels ? JSON.parse(state.taxAndFeeLabels) : {};
};

export const getIsCancelReservationEnabled = state => {
  return state.isCancelReservationEnabled;
};

export const getIsAddGuestsEnabled = state => {
  return state.isAddGuestsEnabled;
};

export const getBirthdayPartyInclusionList = state => {
  return state.birthdayPartyInclusionList;
};

export const getIsBdayBookingWidgetActive = state => {
  return state.isBdayBookingWidgetActive;
};

export const getBeginPartyReservationsDate = state => {
  return state.beginPartyReservationsDate;
};
export const getPartyKidsPerAdultRatio = state => {
  return state.partyKidsPerAdultRatio;
};
export const getPartyMaxGuestCount = state => {
  return state.partyMaxGuestCount;
};

export const getMinimumBookingDaysRestriction = state => {
  return state.minimumBookingDaysRestriction;
};
export const getMaximumBookingDaysRestriction = state => {
  return state.maximumBookingDaysRestriction;
};

export const getShowDiningPageInUFFFlow = state => {
  return state.showDiningPageInUFFFlow;
};

export const getIdMeParameters = state => {
  return state.idMeParameters;
};

export const getIsHoldCancelReservationEnabled = state => state.isHoldCancelReservationEnabled;
