import get from 'lodash-es/get';
import { SUITE_TYPES } from './constants';

export const EReservationStatus = {
  RESERVED: 'RESERVED',
  CHANGED: 'CHANGED',
  CANCELED: 'CANCELED',
  PAST: 'PAST'
};

export const SuiteStatus = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  RESERVED: 'RESERVED',
  CANCELED: 'CANCELED'
};

export const arrivalAliases = ['staystart', 'arrival', 'checkin'];
export const departureAliases = ['stayend', 'departure', 'checkout'];
export const numberOfAdultsAliases = ['numberOfAdults', 'num_adults', 'adults'];
export const kidsAgesAliases = ['kidsAges', 'children_ages'];
export const offerCodeAliases = ['offerCode', 'offer_code', 'promoCode', 'promo_code'];
export const locationAliases = ['location'];
export const suiteTypeAliases = [
  'suite_type',
  'suiteType',
  'room_cat',
  'roomcat',
  'roomCat',
  'room_type',
  'roomType',
  'roomtype'
];

/**
 * @function
 * This adds and parse query string params being sent on a different way, so we handle
 * different aliases for the
 * @param {Object} campaignObject, the availability query string params as object *
 */
export const parseEmailCampaignToAvailability = campaignObject => {
  // Get arrival
  const arrival = getAvailabilityPropByAliases(campaignObject, arrivalAliases);
  // Get Departure
  const departure = getAvailabilityPropByAliases(campaignObject, departureAliases);
  // Get Number of adults
  const numberOfAdults = getAvailabilityPropByAliases(campaignObject, numberOfAdultsAliases, 1);
  // Get Kids Ages
  const kidsAges = getAvailabilityPropByAliases(campaignObject, kidsAgesAliases, '');
  // Get Offer Code
  const offerCode = getNormalizedCode(getAvailabilityPropByAliases(campaignObject, offerCodeAliases, ''));
  // Get Location
  const location = getAvailabilityPropByAliases(campaignObject, locationAliases);
  // Get Suite Type
  const suiteType = getAvailabilityPropByAliases(campaignObject, suiteTypeAliases, SUITE_TYPES.unknown);

  const availabilityObject = {
    ...campaignObject,
    arrival,
    departure,
    numberOfAdults,
    kidsAges,
    offerCode,
    location,
    suiteType
  };

  return availabilityObject;
};

/**
 * @function
 * Explore and check all the aliases for a parameter and translate them as our app needs
 * @param {Object} campaignObject -- The query string param
 * @param {Array} aliases         -- The aliases for an app prop coming from the query string
 * @param {Object} defaultValue   -- A default value if there is no incoming param inside the query string
 */
export const getAvailabilityPropByAliases = (campaignObject, aliases, defaultValue = null) => {
  let returnData = defaultValue;
  aliases.forEach(alias => {
    const data = get(campaignObject, alias, null);
    if (data !== null) {
      returnData = data;
    }
  });

  return returnData;
};

/**
 * @function
 * This function is in charge to translate the different ways to receive the suite type
 * @param {Object} campaignObject -- The query string param
 */
export const withSuiteTypeAliases = campaignObject => {
  // Get Suite Type
  const suiteType = getAvailabilityPropByAliases(campaignObject, suiteTypeAliases, SUITE_TYPES.unknown);

  const availabilityObject = {
    ...campaignObject,
    suiteType: suiteType
  };

  return availabilityObject;
};

export const getNormalizedCode = code => {
  if (code && typeof code === 'string') {
    return code.toUpperCase().trim();
  }
  return code;
};
